var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: { click: _vm.redirectBack },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("div", { staticClass: "d-flex flex-row " }, [
            _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
              _c(
                "div",
                [
                  this.companies != null
                    ? _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "show-select": "false",
                          search: _vm.companiesSearch,
                          items: _vm.companies,
                          headers: this.companiesHeader,
                          dense: "",
                          "item-key": "company_id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { flat: "", height: "auto" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ma-3" },
                                                [
                                                  _c(
                                                    "v-toolbar-title",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold text-h4",
                                                    },
                                                    [_vm._v(" Companies ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-5" },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      label: "Search",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.companiesSearch,
                                                      callback: function ($$v) {
                                                        _vm.companiesSearch =
                                                          $$v
                                                      },
                                                      expression:
                                                        "companiesSearch",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1658865455
                        ),
                        model: {
                          value: _vm.selectedCompanies,
                          callback: function ($$v) {
                            _vm.selectedCompanies = $$v
                          },
                          expression: "selectedCompanies",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
              _c("div", { staticClass: "ml-3" }, [
                this.selectedCompanies != null &&
                Object.keys(this.selectedCompanies).length != 0
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "secondary white--text text-h7",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-3",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-toolbar-title", [
                                                _vm._v(" Selected companies "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-3",
                                  staticStyle: {
                                    "max-height": "300px",
                                    "overflow-y": "scroll",
                                  },
                                },
                                _vm._l(
                                  this.selectedCompanies,
                                  function (company, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "elevation-1": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        index + 1 + " . "
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        company.company_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-card",
                            {},
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color: "secondary white--text text-h6",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-3",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-toolbar-title", [
                                                _vm._v(" Task "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "pa-5" },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Priority",
                                      items: _vm.priorityOption,
                                    },
                                    model: {
                                      value: _vm.form.priority,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "priority", $$v)
                                      },
                                      expression: "form.priority",
                                    },
                                  }),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Category",
                                      items: _vm.categoryOption,
                                    },
                                    model: {
                                      value: _vm.form.category,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "category", $$v)
                                      },
                                      expression: "form.category",
                                    },
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      filled: "",
                                      dense: "",
                                      placeholder: "Description...",
                                      solo: "",
                                      counter: 500,
                                      rules: _vm.rulesMaxLength,
                                    },
                                    model: {
                                      value: _vm.form.action,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "action", $$v)
                                      },
                                      expression: "form.action",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-end pb-3 px-3" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: { color: "primary", plain: "" },
                                      on: { click: _vm.redirectBack },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.validateInput },
                                    },
                                    [_vm._v(" Confirm ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-toolbar",
                              {
                                attrs: {
                                  color: "secondary white--text text-h7",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-3",
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-toolbar-title", [
                                              _vm._v(" Selected companies "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "pt-3" }, [
                              _c(
                                "span",
                                { staticClass: "text-h7 ma-3 grey--text" },
                                [_vm._v(" No Company Selected ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }